import { createRoot } from "react-dom/client";

import Gallery from "./Gallery";
import initializeIsotope from "./initializeIsotope";

const galleryPageGalleryEl = document.querySelector(
  '[data-new-gallery="true"]',
);

function init() {
  if (
    galleryPageGalleryEl &&
    galleryPageGalleryEl.getAttribute("data-new-gallery") === "true"
  ) {
    filterGalleryPage();
    galleryPageGalleryEl.classList.remove("d-none");

    initializeIsotope();
  }

  if (document.querySelector(".new-gallery") !== null) {
    const galleryPlaceholder = document.querySelector(".new-gallery");
    const folderFilter = galleryPlaceholder.dataset.categories;

    const root = createRoot(galleryPlaceholder);

    root.render(<Gallery filter={folderFilter} />);
  }
}

export default init;

function filterGalleryPage() {
  const galleryElement = galleryPageGalleryEl;

  const hiddenCategories = findHiddenCategories(galleryElement);
  return hideItemsBasedOnFilter(hiddenCategories);
}

function hideItemsBasedOnFilter(hiddenCategories) {
  hiddenCategories.forEach((filter) => {
    const itemsToHide = document.querySelectorAll(`a.${filter}`);

    itemsToHide.forEach((item) => {
      item.remove();
    });
  });
}

function findHiddenCategories(galleryElement) {
  const allLis = galleryElement.querySelectorAll("li[data-filter]");
  const hiddenLis = galleryElement.querySelectorAll('li[data-hide="true"]');

  hiddenLis.forEach((item) => {
    if (item.dataset.hide) {
      item.style.display = "none";
    }
  });

  const hiddenCategories = Array.from(hiddenLis).map(
    (li) => li.getAttribute("data-filter")?.split(".")[1],
  );

  const allLiValues = Array.from(allLis).map(
    (li) => li.getAttribute("data-filter")?.split(".")[1],
  );

  const filterLis = allLiValues.filter(
    (item) => !hiddenCategories.includes(item),
  );

  if (filterLis.length === 1 && filterLis.includes(undefined)) {
    const allLi = galleryElement.querySelector('li[data-filter="*"]');
    allLi.style.display = "none";
  }

  return hiddenCategories;
}
