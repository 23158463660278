export default function initializeIsotope() {
  // First remove the Isotope initialized by the main.js of the eSite
  // otherwise the images will be displayed with wrong position
  var iso = $(".gallery-items").data("isotope");
  if (iso) {
    $(".gallery-items").isotope("destroy");
  }

  const galeryItemsElement = document.querySelector(".gallery-items");

  if (galeryItemsElement) {
    var e = $(".gallery-items").isotope({
        itemSelector: ".gallery-item",
        layoutMode: "masonry",
        containerStyle: { overflow: "hidden", position: "relative" },
        filter: document.querySelector(".gallery-filters li")
          ? document
              .querySelector(".gallery-filters li:first-child")
              .getAttribute("data-filter")
          : "*",
      }),
      t = document.querySelectorAll(".gallery-filters li");
    t.length > 0 &&
      t.forEach(function (l) {
        l.addEventListener("click", function () {
          t.forEach(function (e) {
            e.classList.remove("filter-active");
          }),
            l.classList.add("filter-active");
          var r = l.getAttribute("data-filter");
          e.isotope({ filter: r });
        });
      }),
      e.on("arrangeComplete", function () {
        document.querySelectorAll(".gallery-item").forEach(function (e) {
          null === e.offsetParent
            ? e.classList.remove("mag")
            : e.classList.add("mag");
        });
      }),
      $(".gallery-items").magnificPopup({
        type: "image",
        delegate: "a.mag",
        gallery: { enabled: !0 },
        zoom: {
          enabled: !0,
          duration: 300,
          opener: function (e) {
            return e.find("img");
          },
        },
      });
    var l = document.querySelector(".gallery-items"),
      r = document.querySelectorAll("img");
    imagesLoaded(l, function () {
      e.isotope({ itemSelector: ".gallery-item" }),
        r.forEach(function (t) {
          t.addEventListener("load", function () {
            e.isotope("layout");
          });
        }),
        (document.querySelector(".gallery-container").style.display = "block"),
        e.isotope("layout");
    });
  }

  const galleryGridElement = document.querySelector(".gallery-grid");
  if (galleryGridElement) {
    var t = new Masonry(galleryGridElement, {
      itemSelector: ".gallery-grid-item",
      columnWidth: ".gallery-grid-sizer",
      percentPosition: !0,
    });
    $(".gallery-grid").magnificPopup({
      type: "image",
      delegate: "a.mag",
      gallery: { enabled: !0 },
      zoom: {
        enabled: !0,
        duration: 300,
        opener: function (e) {
          return e.find("img");
        },
      },
    });
    var l = document.querySelectorAll("img");
    imagesLoaded(e, function () {
      t.layout(),
        l.forEach(function (e) {
          e.addEventListener("load", function () {
            t.layout();
          });
        }),
        (document.querySelector(".gallery-wrapper").style.display = "block"),
        t.layout();
    });
  }

  document.querySelector(".nav-link") &&
    document.querySelectorAll(".nav-link").forEach(function (e) {
      e.addEventListener("click", function () {
        ScrollTrigger.refresh(!0);
      });
    });
}
